.head-pie {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: start;
    color: var(--main2-color);
}
.wrapper-donut{
    position: relative;
}
.wrapper-donut p{
    position: absolute;
    bottom: 10px;
    left: 50px;
}
.pie-container {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    margin: 0px 0px 15px;
    transition-duration: 1s;
}

/*.pie-container:hover{*/
/*    transform: scale(1.05,1.05);*/
/*    transition-duration: 1s;*/
/*}*/
.donut-container {
    display: flex;
    background-color: white;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    transition-duration: 1s;
}
/*.donut-container:hover{*/
/*    transform: scale(1.05,1.05);*/
/*    transition-duration: 1s;*/
/*}*/
.content-desc {
    display: flex;
    margin: 5px 0px;
    width: 400px;
    border-top: 1px solid gray;
}

.content-desc div {
    margin: 5px;

}

.point-date {
    /* background-color: aqua; */
    padding: 5px;
    border-radius: 5px;
    /* flex: 0 1 auto; */
}

.text-desc {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex: 2 1 150px;
}

.text-num {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 100px;
}

.text-num p {
    margin: 0px;
}

.data-description {
    margin-top: 30px;
    /*flex: 1 1 auto;*/
}

.box-color {
    padding: 10px;
    /*size: 0px;*/
    border-radius: 10px;
    margin-right: 5px;

}
.data-label {
    display: flex;
    align-items: center;
    margin: 5px;
}

.text-label {
    font-size: 12px;
}

.legend-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/*
.text-perc {
  flex: 2 1 auto;
} */
@media only screen and (max-width: 600px) {
    .text-label {
        font-size: 8px;
    }
    .data-label {

        margin: 2px;
    }
    .box-color {
        padding: 5px;
        /*!*size: 0px;*!*/
        /*border-radius: 10px;*/
        /*margin-right: 5px;*/

    }
    .content-desc {

        width: 100% !important;

    }
}
@media only screen and (max-width: 1000px) {

    .content-desc {

        width: 100% !important;

    }
}
