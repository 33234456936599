.top-bar {
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: #FFF;
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    font-family: 'Kanit', sans-serif !important;
}
.top-bar .ui.tiny.avatar.image{
    width: 50px !important;
    height: 50px !important;
    object-fit: cover;
}
.top-bar .account-menu{
    display: flex;
    align-items: center;
}

.top-bar .account-menu .account-pic-profile{
    border-radius: 25px;
    width: 50px;
}

.top-bar .account-info{
    text-align: right;
    padding-right: 10px;
}
.top-bar .account-info .topic{
    font-size: 18px;
    color: #00396d;
}

.top-bar .labeled.dropdown{
    display: flex;
    align-items: center;
}

.top-bar .permission{
    font-size: 14px;
    color: #5c5c5c;
}
