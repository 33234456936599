.setting-table-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
}

.setting-table-screen i.icon {
    padding-left: 3px;
}

.setting-table-screen .table-name-header {
    width: 100%;
}

.setting-table-screen .detail-table {
    flex: 1;
}

.setting-table-screen .search-container {
    display: flex;
    border: solid 1px #d0d0d0;
    padding: 5px 10px;
    border-radius: 10px;
    color: #9f9f9f;
    background-color: #FFF;
}

.setting-table-screen .search-container > input {
    border: none;
    background-color: transparent;
    outline: none;
    display: flex;
    flex: 1;
}

.setting-table-screen .add-input {
    display: flex;
    align-items: center;
}

.setting-table-screen .add-input > input {
    width: 100%;
    padding: 8px 10px;
    border: solid 1px #d0d0d0;
    border-radius: 10px;
    outline: none;
}

.setting-table-screen .add-input .add-button {
    padding: 7px 8px;
    border: none;
    border-radius: 10px;
    background-color: #2A85D7;
    color: #FFFF;
    min-width: 155px;
    margin-left: 10px;
    outline: none;
    cursor: pointer;
}


.setting-table-screen .block-table {
    width: 100%;
    display: flex;
    align-items: center;
}

.setting-table-screen .block-table .edit-button {
    border-radius: 15px;
    border: none;
    padding: 7px 5px;
    text-align: center;
    background-color: #F2C94C;
    margin: 0 5px;
    color: #FFF;
    outline: none;
    cursor: pointer;
}

.setting-table-screen .block-table .edit-button:active {
    background-color: #8b752c;
}

.setting-table-screen .block-table .del-button {
    border-radius: 15px;
    border: none;
    padding: 7px 5px;
    text-align: center;
    background-color: #EB5757;
    margin: 0 5px;
    color: #FFF;
    outline: none;
    cursor: pointer;
}

.setting-table-screen .block-table .edit-container {
    display: flex;
    flex: 1;
    align-items: center;
    border: solid 1px #d0d0d0;
    border-radius: 10px;
}

.setting-table-screen .block-table .edit-container > input {
    width: 100%;
    padding: 8px 10px;
    outline: none;
    border: none;
    border-radius: 10px;
}

.setting-table-screen .block-table .edit-container > a {
    padding-right: 5px;
    color: #9f9f9f !important;
    cursor: pointer;
}

.setting-table-screen .block-table .edit-container > i.icon {
    color: #9f9f9f !important;
    font-size: 12px;
}

.setting-table-screen .block-table .save-button {
    padding: 7px 8px;
    border: none;
    border-radius: 10px;
    background-color: #2A85D7;
    color: #FFFF;
    min-width: 100px;
    margin-left: 10px;
    outline: none;
}

.setting-table-screen .block-table .close-button {
    border: none;
    color: #2A85D7;
    margin-left: 10px;
    outline: none;
    cursor: pointer;
}
