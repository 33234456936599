.sign-container {
    background-color: #ffffff;

    align-self: center;
    border-radius: 10px;

}
.sign-container:hover .icon-male{
    transform: scale(1.4,1.4);
    transition-duration: 1s;
}
.sign-container:hover .icon-smoke{
    transform: scale(1.4,1.4);
    transition-duration: 1s;
}
.sign-container:hover .icon-money{
    transform: scale(1.4,1.4);
    transition-duration: 1s;
}
.sign-container:hover .icon-saving{
    transform: scale(1.4,1.4);
    transition-duration: 1s;
}
.ui.celled.grid {
  box-shadow: none!important;
}
.ui.celled.grid > .row {
  box-shadow: none;
}
.icon-male {
    position: absolute;
    top: -25px;
    color: #72a7d8;
    transition-duration: 1s;
}
.icon-smoke{
    position: absolute;
    top: -30px;
    width: 55px;
    fill: #0d4e89;
}
.icon-saving{
    position: absolute;
    top: -20px;
    width: 50px;
    fill: #0d4e89;
}
.icon-money{
    position: absolute;
    top: -25px;
    width: 55px;
    fill: #0d4e89;
}
.icon-beer{
    position: absolute;
    top: -20px;
    width: 45px;
    fill: #0d4e89;
}
.grid-img {
    position: relative;
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 100px;
}
.sign-amount {
    display: flex !important;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 8px !important;
}
.sign-amount p {
    margin: 0px 5px;

    font-size: 1.5rem;
}
.sign-sex {
    padding: 5px 20px !important;
}
.sign-sex p {
    font-size: 1.5rem;
}
.bg-sign {
    background: linear-gradient(
            rgba(255, 255, 255, 0.9),
            rgba(255, 255, 255, 0.9)
    ),
    url(silk-pattern.png) center center;
    /* background-size: cover; */
    background-repeat: repeat;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.text-amount {
    color: rgb(78, 78, 78);
}
.text-unit {
    color: rgb(110, 110, 110);
}
.sign-container .ui.celled.grid > .row > .column {
    box-shadow: none!important;
}
@media only screen and (max-width: 768px) {
    .icon-male {
        font-size: 4rem !important;
        top: -25px;
    }

    .sign-amount p {
        font-size: 1rem;
    }

    .sign-sex p {
        font-size: 1rem;
    }

    /*.test-left .child .sign-container .sign-amount p {*/
    /*    font-size: 12px;*/
    /*}*/

    /*.test-left .child .sign-container .sign-sex p {*/
    /*    font-size: 15px;*/
    /*}*/
}
@media screen and (max-width: 1024px) {
    .sign-amount p {
        font-size: .9rem;
    }

    .sign-sex p {
        font-size: .9rem;
    }
}

