.change-password-screen {
    font-family: 'Kanit', sans-serif;
    display: flex !important;
    flex-direction: column;
}


.change-password-screen .header-change-password {
    display: block;
    background: #fff;
    margin: 0;
    padding: 10px;
    box-shadow: none;
    color: rgba(0, 0, 0, .85);
    border-bottom: 1px solid rgba(34, 36, 38, .15);
}

.change-password-screen .header-change-password .header-container {
    display: flex;
    flex-direction: row;
}

.change-password-screen .header-change-password .header-container .header-text-container {
    flex: 1;
    display: flex;
    align-items: center;
}

.change-password-screen .header-change-password .header-container .header-text-container .icon-password {
    background-color: #1967AD;
    padding: 15px;
    border-radius: 15px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    margin-right: 10px;
}

.change-password-screen .header-change-password .header-container .header-text-container .header-text {
    font-family: 'Kanit', sans-serif;
}

.change-password-screen .header-change-password .header-container .close-modal-button {
    border: none;
    outline: none;
    padding: 0px 3px 1px 6px;
    background-color: #db2828;
    cursor: pointer;
    border-radius: 15px;
    color: #FFF;
    max-height: 25px;
}

.change-password-screen .content-container {
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.change-password-screen .content-container .container-input {
    display: flex;
    flex: 1;
    align-items: baseline;
    font-size: 12px;
    margin: 5px 0px;
}


.change-password-screen .content-container .container-input > label {
    min-width: 150px;
}

.change-password-screen .content-container .container-input .container-input-password {
    display: flex;
    flex-direction: column;
}

.change-password-screen .content-container .container-input .container-input-password .border-input-password {
    border: solid 1px #979797;
    border-radius: 20px;
    display: flex;
    align-items: center;
}

.change-password-screen .content-container .container-input .container-input-password .border-input-password-error {
    border-radius: 20px;
    display: flex;
    align-items: center;
    border-width: 1px;
    border-image: none;
    border-style: solid;
    border-color: #f98484;
    outline: none;
    background-color: #f7ecec;
}

.change-password-screen .content-container .container-input .container-input-password .input-password {
    /*flex: 1;*/
    min-width: 300px;
    border-radius: 20px;
    border: none;
    padding: 5px 10px;
    border-image: none;
    font-family: 'Kanit', sans-serif !important;
    outline: none;
}

.change-password-screen .content-container .container-input .container-input-password .input-password-error {
    min-width: 300px;
    border-radius: 20px;
    padding: 5px;
    border-width: 0px;
    border-image: none;
    border-style: solid;
    font-family: 'Kanit', sans-serif !important;
    outline: none;
    background-color: #f7ecec;
}

.change-password-screen .content-container .container-input .container-input-password .input-detail-error {
    font-size: 11px;
    color: red;
    padding-left: 5px;

}

.change-password-screen .content-container .container-input .container-input-password .text-success {
    color: #7dbf29;
    font-size: 10px;
}

.change-password-screen .content-container .container-input .container-input-password .text-normal {
    color: red;
    font-size: 10px;
    margin-left: 10px;
}

.change-password-screen .content-container .container-input .container-input-password .icon-success {
    margin-left: 10px;
}

.change-password-screen .content-container .container-input .container-input-password .see-button {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    color: #a4a4a4;
}

.change-password-screen .button-action {
    display: flex;
    margin-top: 15px;
    align-items: center;
}

.change-password-screen .button-action > a {
    text-decoration: underline;
    margin-right: 20px;
    font-size: 12px;
    cursor: pointer;
}

.change-password-screen .button-action .button-save-password {
    border: none;
    font-size: 12px;
    background-image: linear-gradient(#4685BD, #1967AD);
    color: white;
    padding: 8px 40px;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    font-family: 'Kanit', sans-serif !important;
}

/*ui.modal {*/
/*    width: 100%;*/
/*    margin: 0px !important;*/
/*    !*position: absolute; */
/*!* display: none; */
/*!* z-index: 1001;*/

/* */

@media only screen and (max-width: 767px) {
    .ui.modal {
        width: 100%;
        margin: 0px;
    }
}


/*Mobile Screen*/
@media screen and (max-width: 540px) {
    .ui.dimmer {
        padding: 0px !important;
    }

    .ui.modal {
        width: 100% !important;
    }

    .change-password-screen {
        flex: 1 !important;
        margin: 0px !important;
        border-radius: 0px !important;
        position: static !important;
    }

    .change-password-screen .content-container .container-input > label {
        min-width: 100px;
    }

    .change-password-screen .content-container .container-input .input-password {
        min-width: 200px;
    }

    .change-password-screen .ui.modal {
        width: 100% !important;
        margin: 0 !important;
    }

    .change-password-screen .ui.modal > .header {
        padding: 10px !important;
    }

    .change-password-screen .ui.modal {
        width: 100% !important;
        flex: 1;
        margin: 0px;
        border-radius: 0px;
    }


}
