h1 {
	font-family: 'Kanit', sans-serif !important;
}

.address-item span.active {
	color: green
}

.address-item span {
	color: #8e8e8e
}

.ui.input.error > input {
	background-color: #fff6f6;
	border-color: #d01919;
	color: #000000;
	box-shadow: none;
}

.ui.input.success > input {
	background-color: #f6fff6;
	border-color: rgb(78, 165, 114);
	color: #000000;
	box-shadow: none;
}

.field.error .input > input {
	background-color: #fff6f6;
	border-color: #e0b4b4;
	color: #000000;
	box-shadow: none;
}

.field.success .input > input {
	background-color: #f6fff6;
	border-color: rgb(78, 165, 114);
	color: #000000;
	box-shadow: none;
}

/* .error {
	background-color: #fff6f6;
	color: #000000;
	box-shadow: none;
	flex-grow: 1;
	display: flex;
	border: solid 1px #9f3a38;
	padding: 10;
	border-radius: 5;
} */

.ui.basic.label.error {
	background: none #fff6f6;
	border: 1px solid #d01919;
	color: rgba(0, 0, 0, .87);
	box-shadow: none;
}

.ui.basic.label.success {
	background: rgb(246, 255, 246);
	border: 1px solid rgb(78, 165, 114);
	color: rgba(0, 0, 0, .87);
	box-shadow: none;
}

.ui.selection.dropdown.error {
	background: #fff6f6;
	border-color: #e0b4b4;
}

.ui.selection.dropdown.success {
	background: rgb(246, 255, 246);
	border-color: rgb(78, 165, 114);
}

.flexEnd {
	display: flex !important;
	align-items: center;
	justify-content: flex-end;
	padding: 0 !important;
}

.buttonSave {
	font-family: 'Kanit', sans-serif !important;
	border-radius: 50px;
	margin-left: 30px !important;
	padding: 10px 50px !important;
}

.buttonCancel{
  color: #00396D !important;
  margin-right: 10px !important;
  padding-right: 2px !important;
}

.labelRadio{
	flex-grow: 1;
	display: flex;
	border: 1px solid rgba(118, 118, 118, 0.28);
	padding: 10px;
	border-radius: 5px;
}

.labelRadioError{
	flex-grow: 1;
	display: flex;
	border: 1px solid #e0b4b4;
	padding: 10px;
	border-radius: 5px;
	background-color: #fff6f6;
	color: #e0b4b4;
}

.labelRadioSuccess{
	flex-grow: 1;
	display: flex;
	border: 1px solid rgb(78, 165, 114);
	padding: 10px;
	border-radius: 5px;
	background-color:rgb(246, 255, 246);
	color: rgb(78, 165, 114);
}

.displayCenter {
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.buttonCancelCross {
	font-family: 'Kanit', sans-serif !important;
	background-color: white !important;
	padding-right: 0 !important;
}

.UCancel {
	color: #00396d;
	margin: left;
}

.buttonClose {
	background-color: white !important;
	border-color: #fff;
}

.heading {
	background-image: linear-gradient(90deg, rgba(0,57,109,1) 0%, rgba(42,133,215,1) 100%);
	margin: 5px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	padding: 10px;
  color: #FFFFFF;
}

.displayFlexEnd {
	display: flex !important;
	align-items: center;
	justify-content: flex-end;
	padding: 0 !important;
}

.set_margin_left_bottom {
	margin-top: 20px;
	margin-bottom: 20px;
}

.set_padding_bottom {
	padding-bottom: 0% !important;
}

.set_color_red {
	color: red !important;
}

.set_padding_top {
	padding-top: 10px !important;
}

.close-button {
  border-color: #FFFFFF !important;
  background-color: #FFFFFF !important;
}

.padding-left {
  padding-left: 100%;
}
