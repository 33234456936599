.setting-app-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
}

.setting-app-screen .header-container {
    left: -28px;
    display: flex;
    align-items: center;
    margin: 10px 0 40px 0;
    position: relative;
    flex-direction: row;
}

.setting-app-screen .header-container .button-back {
    font-size: 40px;
    outline: none;
    border: none;
    cursor: pointer;
}

.setting-app-screen .header-container  i{
    background-color: #ffffff;
    border-radius: 20px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-color);
}

.setting-app-screen .header-container .header-taxt-breadcumb {
    display: flex;
    flex-direction: column;
}

.setting-app-screen .header-container .header-taxt-breadcumb .header-text {
    font-size: 24px;
    color: var(--main-color);
    margin-bottom: 10px;
    font-weight: bold;

}

.setting-app-screen i.icon {
    padding-left: 3px;
}


