
.show-detail-household {
    background-color: #EDEDED;
    padding: 25px 30px;
    height: 100%;
    overflow: scroll;
}

.rodal-close {
    display: none;
}

.rodal-dialog-overide {
    position: absolute;
    width: 75%;
    right: 0;
    top: 0;
    bottom: 0;
    left: unset;
    animation-duration: 300ms;
    padding: 0;
}


.modal-slide {
    width: 100%;
    height: 100%;
    animation-duration: 300ms;
}

.show-detail-household .button-back {
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
}

.show-detail-household .button-back .button-back-color {
    color: #01396c ;
}

.show-detail-household .approve-subcontant-container {
    padding-bottom: 25px;
    display:flex ;
}

.show-detail-household .subcontant-container {
    padding-bottom: 25px;
    height: 100%;
}

.show-detail-household .button-subcontant-container {
    display: flex;
    flex: 1;
    justify-content: center;
}

.show-detail-household .button-subcontant-container .button-next-page {
    background-color: #1967AD;
    margin-right: 5px;
    font-family: 'Kanit', sans-serif !important;
    color: #ffffff;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.show-detail-household .survey-person-detail {
    padding-bottom: 20px;
}
