.address-header {
    color: #013B70;
    font-weight: bold;
    font-size: 16px;
    line-height: 2em;
}

.address-topic {
    font-size: 14px;
    font-weight: bold;
    color: #656565;
}

.address-detail {
    font-size: 15px;
    color: #656565;
}


.line-height-row {
    margin-top: -28px !important;
}

/* iPad */
@media screen and (max-width: 767px) {

    .address-header {
        font-size: 16px;
        text-align: center;
    }

    .address-topic {
        font-size: 13px;
    }

    .address-detail {
        font-size: 13px;
    }
}

@media screen and (max-width: 490px) {

    .address-header {
        text-align: left;
    }

}
