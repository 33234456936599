.setting-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
}

.setting-screen .button-setting-app-container {
    display: flex;
    align-items: center;
    padding: 20px 30px;
    border: none;
    background-image: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    border-radius: 10px;
    margin-right: 15px;
    position: relative;
    z-index: 0;
    width: 235px;
    margin-bottom: 15px;
    cursor: pointer;
}

.setting-screen .button-setting-app-container::after {
    content: "";
    background-image: url("../../assets/img/silk_pattern.png");
    background-size: 80px 80px;
    opacity: 0.04;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -99;
}

.setting-screen .button-setting-app-container .icon-setting-app {
    padding: 8px 4px 8px 7px;
    border-radius: 20px;
    background-image: linear-gradient(#f5f5f5, #cccccc);
    margin-right: 15px;
    color: var(--main-color);
    font-size: 24px;
}

.setting-screen .button-setting-app-container .header-setting-container {
    color: #FFF;
}

.setting-screen .button-setting-app-container .header-setting-container .header-setting-text {
    font-size: 25px;
    margin-bottom: 5px;
}

.setting-screen .button-setting-app-container .header-setting-container .subheader-setting-text {
    font-size: 18px;
}

