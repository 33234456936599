button {
    font-family: 'Kanit', sans-serif !important;
}
.update-image-profile {
    display: flex;
}

.update-image-profile .img-profile-content {
    display: flex;
    position: relative;
}

.update-image-profile .img-profile {
    width: 200px;
    margin: 10px 50px;
    border-radius: 100px;
}

.update-image-profile .icon-uploadImg {
    background-color: #dedede;
    color: #4a4a4a;
    border: none;
    padding: 10px 6px 10px 8px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    right: 60px;
    font-size: 28px;
    cursor: pointer;
    outline: none;
}

.update-image-profile .icon-cancelImg {
    background-color: #f15540;
    color: #FFF;
    border: none;
    padding: 6px 2px 6px 5px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 70px;
    font-size: 20px;
    cursor: pointer;
    outline: none;
}
