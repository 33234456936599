.page-error{
    font-family: 'Kanit', sans-serif;
    display: flex;
    min-height: calc(100vh) ;
    align-items: center;
    padding: 40px;
    flex-wrap: wrap-reverse;
}
.page-error .container-text{
    /*flex: 1;*/
    margin-top: -150px;
    min-width: calc(50% - 50px);
    text-align: right;
    padding-right: 40px;
}
.page-error .container-text h1{
    font-family: 'Kanit', sans-serif !important;
    font-size: 45px;
    font-weight: bold;
}

.page-error .container-text .detail{
    margin-top: 30px;
    font-size: 20px;
    padding-right: 20px;
}
.page-error .container-text .detail div{
    margin: 10px 0;
}

.page-error .container-text .ex-detail {
    margin-top: 50px;
    /*text-align: center;*/
    margin-right: 60px;
}

.page-error .container-text .ex-detail button{
    cursor: pointer;
    outline: none;
    background-color: #00396D;
    color: #FFF;
    padding: 10px 30px;
    border: none;
    border-radius: 30px;
    font-family: 'Kanit', sans-serif;
    font-weight: normal !important;
    margin-top: 5px;
}
.page-error .container-text .ex-detail button:active{
    background-color: #00274f;
}

.page-error .container-img{
    flex: 1;
    margin-top: -150px;
}
.page-error .container-img img{
    width: 100%;
    max-width: 500px;
    min-width: 350px;
}

/* Ipad Screen */
@media screen and (max-width: 1045px) {
    .page-error{
        flex-wrap: wrap-reverse;
    }

    .page-error .container-img {
        text-align: center;
    }
    .page-error .container-img img{
        max-width: 350px;
        min-width: 100px;
    }

    .page-error .container-text{
        align-self: flex-end;
        margin-top: 0;
        width: 100%;
        text-align: center;
        padding: 0;
    }
    .page-error .container-text .detail{
        padding: 0;
    }
    .page-error .container-img{
        align-self: flex-start;
        flex: 1;
        margin-top: 0;
    }

    .page-error .container-text .ex-detail{
        margin-right: 0;
    }
}
