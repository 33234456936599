.nav.toggle .toggle-menu {
    transform: rotate(180deg);
}

.nav.toggle {
    width: 80px;
}

.nav.toggle ~ .main-container{
    margin-left: 80px;
}

.nav {
    overflow: auto;
    background: rgb(0, 57, 109);
    background: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    width: 250px;
    height: 100vh;
    position: fixed;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    transition: 0.5s;
    z-index: 99;
}

.nav::after {
    content: "";
    background-image: url("../../assets/img/silk_pattern.png");
    background-size: 80px 80px;
    opacity: 0.04;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -99;
}


/* Ipad Screen */
@media screen and (max-width: 850px) {
    .nav.toggle {
        width: 80px;
        height: 70px;
        overflow: hidden;
        background: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    }
    .nav{
        position: fixed;
        width: 100vw;
        overflow-x: hidden;
    }
    .nav ~ .main-container{
        margin-left: 0 !important;
    }
}

.menu-item a {
    width: 100%;
}
.nav .toggle-menu {
    position: absolute;
    font-size: 25px !important;
    top: 25px;
    right: 25px;
    color: #FFF;
    cursor: pointer;
    transition: .5s;
}

.nav .toggle-menu:hover {
    color: #cccccc;
}

.nav .logo {
    transition: .5s;
    display: block;
    margin: 60px auto 10px auto;
    width: 130px;
    height: 100px;
}

.nav.toggle .logo{
    margin-top: 70px;
    width: 50px;
    height: 40px;
}

.nav > h1 {
    font-family: 'Kanit', sans-serif;
    text-align: center;
    color: #FFF;
    font-size: 20px;
    font-weight: normal;
    margin: 0;
    transition: .5s;
}
.nav > h3 {
    margin: 0 0 15px 0;
    text-align: center;
    color: #FFF;
    font-weight: normal;
}

.nav.toggle > h1 {
    font-size: 0;
    margin: 0;
}

.nav.toggle > h3 {
    font-size: 0;
    margin: 0;
}

.nav li.menu-item a {
    font-family: 'Kanit', sans-serif !important;
}

.menu-item {
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    margin-left: 45px !important;
}

.nav.toggle .menu-item{
    margin-left: 0 !important;
    flex-direction: column;
    height: 40px;
    text-align: center;
}
.nav .menu-text{
    transition: .5s;
}
.nav.toggle .menu-text{
    font-size: 0;
}

.nav ul {
    position: relative;
    padding: 0;
    margin: 0;
}

.nav ul li a {
    color: #FFF;
    position: relative;
    z-index: 2;
    display: block;
    padding: 10px;
    transition: 0.5s;
}

.nav ul li i {
    position: relative;
    z-index: 2;
    transition: 0.5s;
    margin-top: -5px;
    margin-right: 10px;
}
.nav.toggle ul li i {
    margin: 0;
}

.nav ul li.active a .menu-text{
    color: var(--main-color);
}

.nav ul li {
    margin: 10px 0;
    height: 40px;
    list-style-type: none;
}

.nav li.slide {
    display: none;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    height: 40px;
    margin: 10px 0 0 30px;
    position: absolute;
    width: calc(100% - 30px);
    right: 0;
    z-index: 0;
    background-color: #EBEBEB;
    transition: 0.5s;
}
.nav.toggle li.slide {
    width: calc(100% - 10px);
}


.nav li.slide img.top {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0px;
    top: -30px;
}

.nav li.slide img.bottom {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0px;
    bottom: -30px;
}

@keyframes menu {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu2 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu3 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu4 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu5 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu6 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu7 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu8 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu9 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu10 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu11 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu12 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

@keyframes menu13 {
    0% {
        width: calc(100% - 30px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 30px);
    }
}

.nav li:nth-child(1).active ~ .slide {
    display: block;
    animation-name: menu;
    animation-duration: 0.5s;
    top: -10px;
}

.nav li:nth-child(2).active ~ .slide {
    display: block;
    animation-name: menu2;
    animation-duration: 0.5s;
    top: 40px;
}

.nav li:nth-child(3).active ~ .slide {
    display: block;
    animation-name: menu3;
    animation-duration: 0.5s;
    top: 90px;
}

.nav li:nth-child(4).active ~ .slide {
    display: block;
    animation-name: menu4;
    animation-duration: 0.5s;
    top: 140px;
}

.nav li:nth-child(5).active ~ .slide {
    display: block;
    animation-name: menu5;
    animation-duration: 0.5s;
    top: 190px;
}

.nav li:nth-child(6).active ~ .slide {
    display: block;
    animation-name: menu6;
    animation-duration: 0.5s;
    top: 240px;
}

.nav li:nth-child(7).active ~ .slide {
    display: block;
    animation-name: menu7;
    animation-duration: 0.5s;
    top: 290px;
}

.nav li:nth-child(8).active ~ .slide {
    display: block;
    animation-name: menu8;
    animation-duration: 0.5s;
    top: 340px;
}

.nav li:nth-child(9).active ~ .slide {
    display: block;
    animation-name: menu9;
    animation-duration: 0.5s;
    top: 390px;
}

.nav li:nth-child(10).active ~ .slide {
    display: block;
    animation-name: menu10;
    animation-duration: 0.5s;
    top: 440px;
}

.nav li:nth-child(11).active ~ .slide {
    display: block;
    animation-name: menu11;
    animation-duration: 0.5s;
    top: 490px;
}

.nav li:nth-child(12).active ~ .slide {
    display: block;
    animation-name: menu12;
    animation-duration: 0.5s;
    top: 540px;
}

.nav li:nth-child(13).active ~ .slide {
    display: block;
    animation-name: menu13;
    animation-duration: 0.5s;
    top: 590px;
}

@keyframes toggle-menu {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu2 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu3 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu4 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu5 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu6 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu7 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu8 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu9 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu10 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu11 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu12 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}

@keyframes toggle-menu13 {
    0% {
        width: calc(100% - 10px);
    }
    25% {
        width: 45px;
    }
    50% {
        width: 45px;
    }
    100% {
        width: calc(100% - 10px);
    }
}


.nav li:nth-child(1).active ~ .slide {
    display: block;
    animation-name: toggle-menu;
    animation-duration: 0.5s;
    top: -10px;
}

.nav li:nth-child(2).active ~ .slide {
    display: block;
    animation-name: toggle-menu2;
    animation-duration: 0.5s;
    top: 40px;
}

.nav li:nth-child(3).active ~ .slide {
    display: block;
    animation-name: toggle-menu3;
    animation-duration: 0.5s;
    top: 90px;
}

.nav li:nth-child(4).active ~ .slide {
    display: block;
    animation-name: toggle-menu4;
    animation-duration: 0.5s;
    top: 140px;
}

.nav li:nth-child(5).active ~ .slide {
    display: block;
    animation-name: toggle-menu5;
    animation-duration: 0.5s;
    top: 190px;
}

.nav li:nth-child(6).active ~ .slide {
    display: block;
    animation-name: toggle-menu6;
    animation-duration: 0.5s;
    top: 240px;
}

.nav li:nth-child(7).active ~ .slide {
    display: block;
    animation-name: toggle-menu7;
    animation-duration: 0.5s;
    top: 290px;
}

.nav li:nth-child(8).active ~ .slide {
    display: block;
    animation-name: toggle-menu8;
    animation-duration: 0.5s;
    top: 340px;
}

.nav li:nth-child(9).active ~ .slide {
    display: block;
    animation-name: toggle-menu9;
    animation-duration: 0.5s;
    top: 390px;
}

.nav li:nth-child(10).active ~ .slide {
    display: block;
    animation-name: toggle-menu10;
    animation-duration: 0.5s;
    top: 440px;
}

.nav li:nth-child(11).active ~ .slide {
    display: block;
    animation-name: toggle-menu11;
    animation-duration: 0.5s;
    top: 490px;
}

.nav li:nth-child(12).active ~ .slide {
    display: block;
    animation-name: toggle-menu12;
    animation-duration: 0.5s;
    top:540px;
}

.nav li:nth-child(13).active ~ .slide {
    display: block;
    animation-name: toggle-menu13;
    animation-duration: 0.5s;
    top:590px;
}
