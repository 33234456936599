:root {
    --main-color: rgb(18,95,103);
    --main2-color: rgb(40,130,140);
}

.main-container {
    transition: .5s;
    margin-left: 250px;
    padding-top: 70px;
}
.App{
    min-height: 100vh;
}
html,body{
    font-family: 'Kanit', sans-serif !important;
    background-color: #EBEBEB !important;
    position: relative;
    z-index: -99;
}
