.main-prog-container,
.main-progOnce-container {
  background-color: white;
  border-radius: 10px;
  padding: 15px 10px 0px;
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  transition-duration: 1s;
  height: 100%;
}
.progbar-container {
  display: flex;
  /* background-color: white; */
  padding: 5px 5px 0px;
  /* flex-direction: column; */
  /* padding: 0px 10px 0px 0px; */
  /* justify-content: center; */
}
.desc-bar {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
}
.desc-bar p {
  padding: 0px;
  margin: 0px;
}
.main-progOnce-container .ui.progress .bar{
  min-width: 0 !important;
}
.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--main2-color);
}
.main-prog-container .header .title-icon{
  margin-right: 5px;
}
.cont-progress {
  margin-top: 5px;
}
.prog-top-bar {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.main-progOnce-container .ui.progress {
  background: rgba(233,74,95,.8) !important;
}
.ui.green.progress .bar {
  background-color: rgba(99,210,162,1)!important;
}
