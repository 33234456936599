.detail-profile-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
}

.detail-profile-screen .header-profile {
    font-size: 20px !important;
    font-family: 'Kanit', sans-serif !important;
    color: #464646;
}

.detail-profile-screen .profile-user {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 15px;
}

.detail-profile-screen .container-subheader {
    display: flex;
    padding: 10px 20px;
    border-bottom: solid 2px #EBEBEB;

}

.detail-profile-screen .container-subheader .subheader {
    display: flex;
    align-items: center;
    flex: 1;
}

.detail-profile-screen .container-subheader .subheader .icon-header {
    font-size: 28px;
    margin-right: 8px !important;
    color: #013B70;
}

.detail-profile-screen .container-subheader .subheader .name-user {
    font-size: 18px;
    font-weight: bold;
}

.detail-profile-screen .container-subheader .subheader .id-card {
    font-size: 12px;
    color: #B7B7B7;
    font-weight: bold;
}

.detail-profile-screen .container-subheader .ui.divider {
    margin: 0px !important;
}

.detail-profile-screen .container-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.detail-profile-screen .container-content .img-profile {
    max-width: 170px;
    margin: 10px 50px;
    border-radius: 100px;
}

.detail-profile-screen .container-content .divider-profile {
    border-left: solid 1px #EBEBEB;
    min-height: 450px;
    margin: 0px 10px;
}

.detail-profile-screen .container-content .container-content-input {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 10px 50px;
}

.detail-profile-screen .profile-user .container-content .container-information {
    display: flex;
    background-color: #EBEBEB;
    padding: 8px;
    border-radius: 22px;
    align-items: center;
    margin-bottom: 10px;
}


.detail-profile-screen .profile-user .container-content .container-information .container-icon-topic {
    padding: 5px 6px;
    border-radius: 15px;
    background-image: linear-gradient(#4786BE, #1967AD);
    margin-right: 10px;

}

.detail-profile-screen .profile-user .container-content .container-information .icon-topic {
    color: #FFFFFF;
    margin: 0px !important;

}

.detail-profile-screen .profile-user .container-content .container-information .detail-topic {
    display: flex;
    flex-direction: column;
}

.detail-profile-screen .profile-user .container-content .container-information .detail-topic .detail-sub-topic {
    font-size: 11px;
    color: #a2a2a2;
}

.detail-profile-screen .profile-user .container-content .container-information .detail-topic span {
    color: #4a4a4a;
}

.detail-profile-screen .container-button-action {
    display: flex;
}

.detail-profile-screen .container-button-action .button-action {
    border-radius: 20px;
    padding: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.detail-profile-screen .container-button-action .button-action .icon-password {
    background-color: #FFFFFF;
    padding: 15px;
    border-radius: 15px;
    color: #1967AD;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detail-profile-screen .container-button-action .button-action .icon-edit {
    background-color: #FFFFFF;
    padding: 12px;
    border-radius: 15px;
    color: #E6A935;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detail-profile-screen .container-button-action .button-action span {
    color: #FFFFFF;
    font-family: 'Kanit', sans-serif;
    padding: 0px 10px;
    font-size: 12px !important;
}

@media screen and (max-width: 660px) {
    /*.detail-profile-screen .container-content*/
}

/*size Ipad*/
@media screen and (max-width: 680px) {

    .detail-profile-screen .container-button-action {
        margin-bottom: 10px;
    }

    .detail-profile-screen .container-content {
        flex-direction: column;
    }

    .detail-profile-screen .container-content .divider-profile {
        display: none;
    }

    .detail-profile-screen .container-content .img-profile {
        margin: 10px 55px;
    }

    .detail-profile-screen .container-content .container-content-input {
        margin: 10px 55px;
    }

}


/*size mobile*/
@media screen and (max-width: 480px) {
    .detail-profile-screen .container-subheader {
        flex-direction: column;
    }

    .detail-profile-screen .header-profile {
        display: flex;
        flex: 1;
        justify-content: center;
        padding-bottom: 10px;
    }

    .detail-profile-screen .container-button-action .button-action {
        flex: 1;
    }

}



