.height-light {
    font-size: 20px;
    color: red;
}

h2 {
    font-family: 'Kanit', sans-serif !important;
}

:root {
    --screen-height: 100vh;
}

.login-screen, .login-screen .container {
    min-height: 100vh;
    position: relative;
}

.height-light {
    font-family: 'Kanit', sans-serif;
    margin: 0;
    padding: 30px 0 0 30px;
    font-size: 20px;
    color: #ffffff;
}

.main-login {
    padding: 5px;
    height: 100vh;
    position: relative;
}

.background-login {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #16576b;
    z-index: -99;
}

.background-login::after {
    content: "";
    background-image: url("../../assets/img/silk_pattern.png");
    background-size: 80px 80px;
    opacity: 0.09;
    transform: rotate(30deg);
    top: 0;
    left: -200px;
    bottom: -200px;
    right: 0;
    position: absolute;
    z-index: -99;
}

.block-top-right {
    height: var(--screen-height);
    width: calc(100vw - 300px);
    background-color: #F0F5F9;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    z-index: -97;
}

.block-bottom-right {
    z-index: -98;
    width: 100vw;
    height: 250px;
    background-color: #FFF;
    position: absolute;
    bottom: 0;
    right: 0;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
}

.container {
    display: flex;
    /*flex-wrap: wrap;*/
    flex-direction: column;
    padding: 20px;
    flex: 1;
}

.logo-container-login {
    display: flex;
    /*flex: 1;*/
    align-items: center;
}

.logo-header-login {
    border-radius: 100px;
    margin-right: 10px;
    height: 50px;
    width: 67px;
}

.login-screen .logo-login {
    display: none;
}

.logo-text-login {
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
}

.logo-text-sub-login {
    font-size: 14px;
    font-weight: 100;
    padding-top: 0;
    border-top: solid 1px #ffffff2b;
    margin-top: 5px;
    color:#FFF;
}

.body-container {
    display: flex;
    flex: 1;
    width: 100%;
    /*align-items: center;*/
}

.app-container {
    min-width: 500px;
    /*align-self: flex-end;*/
    display: flex;
    flex: 1;
    flex-direction: column;
}

.app-img {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
}


.app-img img {
    max-width: 600px;
    max-height: 500px;
    height: auto;
    width: 100%;
    top: 80px;
    position: relative;
    animation-name: example;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes example {
    0% {
        top: 80px
    }
    50% {
        top: 85px;
    }
    100% {
        top: 80px;
    }
}

@keyframes mini-logo {
    0% {
        top: 0
    }
    50% {
        top: 5px;
    }
    100% {
        top: 0;
    }
}

.download-here {
    display: flex;
    flex-direction: column;
    /*flex: 1;*/
}

.download-text {
    font-family: 'Kanit', sans-serif;
    font-size: 18px;
    color: #FFFFFF;
}

.download-container {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
}

.download-img {
    width: auto;
    height: 100px;
    margin-right: 20px;
    cursor: pointer;

}

.app-img-mobile {
    display: none;
}

.container-login {
    min-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    margin-right: 20%;
}

.container-login .message-error {
    width: 100% !important;
    padding: 10px !important;
    border-radius: 10px !important;
}


.welcome-text {
    font-family: 'Kanit', sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: var(--main-color);
    margin-top: 20px;
}

.system-text {
    background-color: #f0f5f9;
    padding: 10px;
    border-radius: 10px;
    font-family: 'Kanit', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: var(--main2-color);
    margin-top: 20px;
}

.border-input-login {
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border: solid 1px #c4c4c4;
    border-radius: 100px;
    margin-top: 20px;
    padding: 5px 10px;
    width: 100%;
    color: #313337;
}

.invalid-border-input-login {
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border: solid 1px red;
    border-radius: 100px;
    margin-top: 20px;
    padding: 5px 10px;
    width: 100%;
    color: #313337;
}

.border-input-login i {
    margin-top: -7px !important;
}

.input-login {
    outline: none;
    border: none;
    padding: 5px;
    background-color: transparent;
    /*width: 350px;*/
    flex: 1;
    font-family: 'Kanit', sans-serif;
}

.invalid-text {
    color: red;
    font-size: 12px;
    font-family: 'Kanit', sans-serif;
    margin: 3px 12px 0px;
    align-self: flex-start;
}

.forgot-password {
    font-family: 'Kanit', sans-serif;
    font-size: 14px;
    align-self: flex-end;
    margin-right: 20px;
    color: #00396D;
    background-color: #f0f5f9;
    padding: 0px 5px;
    border-radius: 5px;
    margin-top: 5px;
}

.button-login {
    cursor: pointer;
    outline: none;
    background-color: var(--main-color);
    color: #FFFFFF;
    font-size: 18px;
    font-family: 'Kanit', sans-serif;
    border-color: #FFFFFF;
    border-radius: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 20px;
}

.button-loading {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    background-color: #002546;
    color: #a5a2a2;
    font-size: 14px;
    font-family: 'Kanit', sans-serif;
    border-color: #a5a2a2;
    border-radius: 100px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 20px;
}

.container-login img.phone-logo {
    display: none;
}

/* Ipad Screen */
@media screen and (max-width: 850px) {
    .system-text {
        background-color: transparent;
        margin: 0;
    }

    .body-container {
        flex-wrap: wrap-reverse;
        justify-content: center;
    }

    .container-login {
        background-color: #ffffffab;
        margin: 30px;
        border-radius: 10px;
        padding: 20px 120px;
        align-items: center;
        flex: 1 1;
    }

    .welcome-text {
        font-family: 'Kanit', sans-serif;
        font-size: 30px;
        font-weight: bold;
        color: var(--main-color);
        margin-top: 0;
    }

    .login-screen .logo-login {
        display: block;
        border-radius: 100px;
        margin-bottom: 20px;
    }

    .app-container {
        justify-content: space-between;
        flex-direction: row-reverse;
        flex: 1;
        padding-bottom: 120px;
    }

    .download-container {
        flex-direction: column;
    }

    .download-img {
        height: 70px;
        margin-bottom: 20px;
    }

    .app-img {
        max-width: 360px;
        max-height: 253px;
        width: 100%;
        height: 100%;
    }

    .download-here {
        padding-left: 30px;
    }

    .forgot-password {
        background-color: transparent !important;
    }
}

/*Mobile Screen*/
@media screen and (max-width: 650px) {
    .app-img-mobile {
        display: none;
    }

    .background-login::after {
        transform: scale(1.4) rotate(30deg);
        opacity: 0.05;
    }

    .block-bottom-right {
        display: none;
    }

    .container-login {
        margin-top: 0;
        background-color: transparent;
        padding: 20px;
    }

    .login-screen .logo-login {
        display: none;
    }

    .welcome-text {
        font-size: 20px;
        color: #FFFFFF;
    }

    .system-text {
        font-family: 'Kanit', sans-serif;
        font-size: 12px;
        color: #FFFFFF;
    }

    .app-container {
        min-width: 200px;
        flex: 1;
        padding-bottom: 0;
    }

    .app-img {
        display: none;
    }

    .download-container {
        flex-direction: row;
        justify-content: space-between;
        padding-right: 0;
    }

    .download-container .download-img:last-of-type {
        margin-right: 0;
    }

    .download-here {
        padding-left: 0;
        flex: 1;
    }

    .body-container {
        flex-wrap: wrap-reverse;
    }

    .logo-header-login {
        height: 45px;
        width: 67px;
    }

    .logo-text-login {
        font-size: 12px;
    }

    .container-login img.phone-logo {
        display: block;
        width: 200px;
        height: auto;
        top: 80px;
        position: relative;
        animation-name: mini-logo;
        animation-duration: 3s;
        animation-iteration-count: infinite;
    }


    .block-top-right {
        height: 320px;
    }

    .forgot-password {
        color: #FFF;
    }

}

