.ui.container.doubling.divided.two.column.grid.main-dash-container {
    margin: 0 !important;
    width: 100% !important;
    padding: 0px 20px;
}

.stretched.row.main-show-area-container {
    padding: 0 !important;
}

.bg-top-container, .bg-top-container2, .container-select {
    color: #696969;
}

.stretched.row.fix-padding {
    padding-top: 0 !important;
}

.loader-container .route-loader {
    margin: 10px 30px !important;
}

.fix-container-left {
    /*padding: 0 !important;*/
    padding-left: 0 !important;
}

.fix-slider-container {

    padding: 0px 35px !important;
}

.fix-slider3-container {

    padding: 0px 35px !important;
}

.ui.container.two.column.grid.main-g3.fix-slider3-container {
    width: 100% !important;
}

.ui.container.stackable.three.column.grid.fix-slider-container {
    width: 100% !important;
}

.ui.container.stackable.two.column.grid.fix-slider-container {
    width: 100% !important;
}

.fix-container-right {
    /*padding: 0 !important;*/
    padding-right: 0 !important;
}

.fix-container {
    padding: 0 !important;
}

.group-left {
    /*margin-right: 7px;*/

}

.test-left {
    display: flex;
    flex-direction: column;
}

.population-right-container .child {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.population-right-container .child .child-col1 {
    margin-right: 5px;
    flex: 1 1 auto;
}

.population-right-container .child .child-col2 {
    margin-left: 5px;
    flex: 1 1 auto;
}

.slick-next,
.slick-prev,
.slick-dots {
    display: none !important;
}

.container-select {
    /*margin-top: 30px;*/
    padding: 0px;
    padding-bottom: 40px;
    height: 100%;
}

.col-mar0 {
}

.header-dash {
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    margin: 0px;
}

.header-dash-text {
    color: #808080;
    font-size: 15px;
    font-weight: lighter;
}

.header-population-text {
    color: var(--main-color);
    font-size: 21px;
    font-weight: bold;
}

.header-home-text {
    color: var(--main-color);
    font-size: 21px;
    font-weight: bold;
}

.header-dash-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.main-title-dash .left-header-container {
    display: flex;
}

.main-title-dash .right-header-container {
    display: flex;
}

.ui.divided.grid:not([class*="vertically divided"]) > .row > .column {
    box-shadow: none !important;
}

.ui.celled.grid > .row {
    box-shadow: none !important;
}

.ui.attached.segment {
    border: none !important;
}

.ui.segment {
    border: none !important;
}

.ui.attached.menu:not(.tabular) {
    border: none !important;
    border-bottom: 1px solid rgba(128, 128, 128, 0.2) !important;
}

.ui.menu .active.item {
    background: rgba(0, 0, 0, .1) !important;
}

.ui.divided.grid:not([class*="vertically divided"]) > .column:not(.row), .ui.divided.grid:not([class*="vertically divided"]) > .row > .column {
    box-shadow: none !important;
}

.main-g3 {
    margin-top: 5px !important;
    display: flex !important;
}

.ui.grid > .column:not(.row) {
    padding-bottom: 0rem !important;
    padding-top: 0rem !important;
}

.loading-screen {
    background-color: #0d4e89;
}

.main-title-dash {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.export-button {
    display: flex;
    border: none;
    position: relative;
    box-shadow: 5px 5px 4px #c7c7c7;
    outline: none;
    background-color: #FFFFFF !important;
    cursor: pointer;
    font-size: 14px !important;
    color: #616161;
    border-radius: 10px;
    align-items: center;
    padding: 10px 30px;
    margin: 5px;
}

.filter-button {
    display: flex;
    border: none;
    box-shadow: 5px 5px 4px #c7c7c7;
    outline: none;
    /* padding: 8px 15px; */
    background-color: #FFFFFF !important;
    cursor: pointer;
    font-size: 14px !important;
    color: #616161;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    font-family: 'Kanit', sans-serif;
    padding: 10px 30px;
    margin: 5px;
}

.filter-modal-screen .modal-content .button-filter-modal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filter-modal-screen .modal-content .modal-detail {
    margin: 20px 0px;
}

.filter-modal-screen .modal-content .button-filter-modal > a {
    text-decoration: underline;
    margin-right: 20px;
    font-size: 12px;
    cursor: pointer;
}

.filter-modal-screen .modal-content .button-filter-modal .button-save-filter-modal {
    border: none;
    font-size: 12px;
    background-image: linear-gradient(#4685BD, #1967AD);
    color: white;
    padding: 8px 40px;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    font-family: 'Kanit', sans-serif !important;
}

.filter-modal-screen .modal-header {
    display: flex;
    justify-content: space-between;

}

.filter-modal-screen .modal-header .left-modal-header {
    display: flex;
    font-size: 18px;
    color: #00396D;
    font-weight: bold;
    justify-content: center;
    align-items: center;
}

.filter-modal-screen .modal-header .close-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EB5757;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    margin: 0px;
    font-size: 12px;
    border: none !important;
}

.filter-modal-screen .modal-header .close-modal-button {
    border: none;
    outline: none;
    padding: 0px !important;
    background-color: transparent;
    cursor: pointer;
}

.title-area-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.title-area-container .title-area-icon {
    padding: 15px 12px;
    background-color: var(--main-color);
    border-radius: 40px;
    position: absolute;
    left: -5px;
    top: -12px;
    color: white;
}

.title-area-container .area-bar {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding-left: 20px;

}

.slide-right {
    animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.scale-up-hor-left {
    animation: scale-up-hor-left 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes slide-right {
    0% {
        transform: translateX(-20px);
        opacity: 0;
    }
    100% {
        transform: translateX(0px);
        opacity: 1.0;
    }
}

@keyframes scale-up-hor-left {
    0% {
        transform: scaleX(0.2);
        transform-origin: 0% 0%;
    }
    100% {
        transform: scaleX(1);
        transform-origin: 0% 0%;
    }

}

.title-area-container .area-bar .area-header {
    display: flex;
    /*justify-content: center;*/
    padding: 10px;
    background-color: #1967AD;
    /*width: 30%;*/
    border-top-right-radius: 23px;
}


.title-area-container .area-bar .area-text {
    display: flex;
    flex-wrap: wrap;
    /*flex: 1 1 10px;*/
    /*flex-direction: column;*/
    padding: 15px 20px;
    background-color: whitesmoke;
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    border-bottom-left-radius: 23px;
}

.title-area-container > span {
    background-color: var(--main-color);
    padding: 10px 30px;
    border-top-right-radius: 23px;
    /*border-bottom-right-radius: 23px;*/
    border-bottom-left-radius: 23px;
    align-self: flex-start;

}

.border-radius-bot-right {
    border-bottom-right-radius: 23px;
}

.title-area-container > span p {
    margin-left: 30px;
    color: white;
}

.title-area-container .area-bar .area-text .all-commu-name {
    /*flex:1 1 auto;*/
    display: flex;
    /*padding: 0px 15px;*/
    color: #1967AD;
}

.title-area-container .area-bar .area-text .all-commu-name p {
    margin: 0px !important;
}

.title-area-container .area-bar .area-text .all-commu-name .community-name-space {
    color: #2e465b;
    padding: 0px 10px;
    /*margin: 0px!important;*/
}

.flex-menu-butt .label-but- {
    font-size: 16px !important;

}

.flex-menu-butt .label-but-false {
    font-size: 16px !important;
    margin-bottom: 0px;
    margin-top: 5px;
}

.flex-menu-butt .label-but-actives {
    font-size: 16px !important;
    margin-top: 5px;
    margin-bottom: 0px;
}

.flex-menu-butt .label-but2-actives {
    font-size: 16px !important;
    margin-top: 5px;
}

.flex-menu-butt .label-but2-false {
    font-size: 16px !important;
    /*margin-bottom: 5px;*/
    margin-top: 5px;
}

@media only screen and (max-width: 767px) {
    /*.container-select {*/
    /*    !*margin-top: 30px;*!*/
    /*    padding: 10px;*/
    /*}*/
    .flex-menu-butt .label-but- {
        font-size: 12px !important;

    }

    .flex-menu-butt .label-but-false {
        font-size: 10px !important;
        margin-bottom: 0px;
        margin-top: 5px;
    }

    .flex-menu-butt .label-but-actives {
        font-size: 10px !important;
        margin-top: 5px;
        margin-bottom: 0px;
    }

    .flex-menu-butt .label-but2-actives {
        font-size: 10px !important;
        margin-top: 5px;
    }

    .flex-menu-butt .label-but2-false {
        font-size: 10px !important;
        /*margin-bottom: 5px;*/
        margin-top: 5px;
    }

    .fix-slider-container {

        padding: 0px 2px !important;
    }

    .fix-slider3-container {

        padding: 0px 14px !important;
    }

    .ui.container.two.column.grid.main-g3.fix-slider3-container {
        width: auto !important;
    }

    .header-container {
        margin-top: 45px !important;
    }

    .main-dash-container .column.main-left-population-container {
        padding-bottom: 0 !important;
    }

    .bg-top-container {
        padding: 15px 0px !important;
        margin-top: 20px !important;
        background-color: rgba(255, 255, 255, .5);
        /*border-radius: 10px;*/
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }

    .bg-top-container2 {
        padding: 15px 0px !important;
        margin-top: 20px !important;
        background-color: rgba(255, 255, 255, .5);
        /*border-radius: 10px;*/
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .container-select {
        padding: 15px 0px !important;
        margin: 0px 18px;
        margin-top: 30px !important;
        background-color: rgba(255, 255, 255, .5);
        border-radius: 10px;
    }

    .progbar-g1 {
        margin-top: 100px !important;
    }

    .ui.stackable.celled.grid > .column:not(.row), .ui.stackable.celled.grid > .row > .column, .ui.stackable.divided:not(.vertically).grid > .column:not(.row), .ui.stackable.divided:not(.vertically).grid > .row > .column {
        border-top: none !important;
    }

    .title-area-container {
        margin-bottom: 15px;
    }

    .right-header-container {
        display: flex;
        flex-direction: column;
    }

    .show-area-container {
        padding: 0px !important;
    }

    .ui.container.container-select {
        padding: 0px 11px !important;
    }

}

@media screen and (max-width: 1024px) {
    .container-select {
        /*margin-top: 30px;*/
        padding: 0px 28px;
    }

    .content-desc {
        width: 300px;
    }

    .fix-slider3-container {
        padding: 0px 6px !important;
    }

    .fix-slider-container {

        padding: 0px 6px !important;
    }
}
