.edit-map-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
    position: relative;
}

.edit-map-screen .detail-content {
    position: absolute;
    left: 0;
    top: 90px;
    padding: 20px;
    z-index: 20;
    background-color: #FFF;
    width: 450px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.edit-map-screen .content-action {
    text-align: center;
    padding-top: 20px;
}

.edit-location.active {
    background-color: var(--main-color);
    color: #FFF !important;
}

.edit-location.disabled{
    background-color: #eaeaea;
    cursor: not-allowed;
}

.edit-location.active i {
    color: #FFF;
}

.edit-location:active {
    background-color: #e8e8e8;
}

.lightbox-container {
    position: relative;
}

.lightbox-container:after {
    content: "";
    display: block;
    padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
}

.lightbox-container .lightbox-img-thumbnail {
    position: absolute; /* Take your picture out of the flow */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; /* Make the picture taking the size of it's parent */
    width: 100% !important; /* This if for the object-fit */
    height: 100% !important; /* This if for the object-fit */
    object-fit: cover; /* Equivalent of the background-size: cover; of a background-image */
    object-position: center;
}

.marker-text {
    top: -28px;
    position: relative;
}

.edit-map-screen .backdrop {
    display: flex;
    flex-direction: column;
    color: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    z-index: 99;
    align-items: center;
    justify-content: center;
}

.edit-map-screen .address-no {
    width: 70px;
    position: relative;
    z-index: 99;
    left: -35px;
    top: -60px;
    text-align: center;
}

.edit-map-screen .backdrop h1 {
    margin: 0 !important;
    margin-top: -20px;
}

.edit-map-screen .backdrop i.disabled.icon {
    font-size: 130px;
    color: #FFF !important;
    opacity: 1 !important;
}

.edit-map-screen .header-content {
    display: flex;
    align-items: center;
    width: 300px;
    position: absolute;
    z-index: 1;
    left: 0;
    background-color: #ffffff;
    padding: 15px 10px 10px;
    border-radius: 0 10px 10px 0;
    font-family: 'Kanit', sans-serif;
}

.edit-map-screen .header-content i {
    font-size: 30px;
    margin-top:5px;
    color: var(--main-color);
    cursor:pointer;

}

.edit-map-screen .header-content .header-text {
    color: #595959;
    font-size: 24px;
    font-weight: 700;
}

.edit-map-screen .header-content .subheader-text {
    color: #808080;
}

.edit-map-screen .map-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.edit-map-screen .icon-marker {
    cursor: pointer;
    width: 80px;
    height: 80px;
    position: relative;
    bottom: 80px;
    right: 40px;
}

.edit-map-screen .icon-cluster {
    cursor: pointer;
    box-shadow: 0 0 14px #4b494933;
    width: 80px;
    height: 80px;
    border: solid 6px #FFF;
    border-radius: 40px;
    background: #0d4e89;
    font-size: 150%;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-map-screen .map-content .filter-content {
    position: absolute;
    top: 20px;
    right: 20px;
}

.edit-map-screen .map-content .filter-house {
    height: 190px;
    background-color: #FFF;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
    transition: 0.5s;
}

.edit-map-screen .map-content .filter-house.select-filter {
    height: 270px;
}

.edit-map-screen .map-content .filter-house.hide {
    height: 49px;
    overflow: hidden;
}

.edit-map-screen .map-content .filter-house > .topic {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 0 10px 0;
    margin-top: -3px;
}

.edit-map-screen .map-content .filter-house > .topic > .map {
    color: var(--main-color);
    opacity: 1 !important;
}

.edit-map-screen .map-content .filter-house > .topic > .filter {
    color: var(--main-color);
    opacity: 1 !important;
}

.edit-map-screen .map-content .filter-house > .topic > .caret.down {
    color: #1967AD;
    opacity: 1 !important;
}

.edit-map-screen .map-content .filter-house > .topic > .window.minimize {
    color: #1967AD;
    opacity: 1 !important;
    margin-bottom: 10px;
}

.edit-map-screen .map-content .filter-house > .topic > h4 {
    color: var(--main2-color);
    margin: 0 !important;
    padding-top: 5px;
    padding-right: 80px;
    font-family: 'Kanit', sans-serif;
}

.edit-map-screen .map-content .filter-house > .search.dropdown {
    margin: 5px 0;
    border-radius: 10px;
}

.edit-map-screen .map-content .icon-checkcox {
    margin-top: -10px;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    vertical-align: middle;
}

.edit-map-screen .map-content .filter-house > .ui.checkbox {
    display: block;
    margin-bottom: 15px;
}

.edit-map-screen .map-content .filter-house .switch-icon {
    position: absolute;
    right: 10px;
}

.edit-map-screen .type-map img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    border: solid 5px #FFF;
    cursor: pointer;
}

.edit-map-screen .type-map {
    float: right;
}

.edit-map-screen button.gm-control-active.gm-fullscreen-control {
    display: none !important;
}

@media screen and (max-width: 425px) {
    .edit-map-screen .map-content .filter-content {
        top: 90px;
    }
}

@media screen and (max-width: 480px) {
    .edit-map-screen .content-action {
        text-align: center;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
    }

    .edit-map-screen .sub-detail-data-house{
        display: none !important;
    }
}

@media screen and (max-width: 1200px) {

    .edit-map-screen .detail-content {
        left: 0;
        right: 75px;
        bottom: 0;
        width: initial;
        top: initial;
    }

    .edit-map-screen .content-marker-detail .detail-data-house{
        display: none;
    }
}

