.main-g2-right-container {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  /*justify-content: space-between;*/
}
/*.main-g2-left-container .child-g2-bot {*/
/*  margin-top: 15px;*/
/*}*/
.main-g2-left-container .child-g2-top .sign-amount{
  padding: 12px 0px!important;
}
.main-g2-left-container .child-g2-top .sign-sex{
  padding: 8.5px 20px!important;
}
.main-g2-right-container .child-g2-col {
  margin-top: 14px;
}
.child-g2-col,.child-g2-col-top {
  /*margin-bottom: 10px;*/
}
@media only screen and (max-width: 767px) {
  .main-g2-left-container .sign-container .icon-male {
  font-size: 50px!important;
    margin-top: 12px;
  }
}