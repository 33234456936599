.location-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #FFFFFF;
    position: relative;
    border-radius: 10px;
    font-family: 'Kanit', sans-serif;
    margin-top: 10px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.map-container {
    position:relative;
    margin: 15px;
    /*margin-bottom: 100px;*/
    min-height: 234px;
    display: flex;
    justify-content: center;
}

.map-styled {
    width: 100% !important;
    height: 100% !important;
}

.btn-location {
    border-radius: 0px 0px 10px 10px !important;
    margin-right: 0px !important;
    font-size: 16px !important;
    background-color: var(--main-color) !important;
    color: #FFFFFF !important;
    cursor: none;
}

.edit-location {
    margin-top:10px;
    margin-right:10px;
    padding: 10px 20px;
    border: 2px solid var(--main-color);
    color: var(--main-color) !important;
    background-color: #FFF;
    border-radius: 3px !important;
    margin-left: 10px;
    cursor: pointer;
}

.edit-location i {
    color: var(--main-color)
}

/*iPad*/
@media screen and (max-width: 768px) {
    .location-container {
        margin-top: 25px !important;
    }
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {
    .map-styled {
        width: 92% !important;
    }

    .btn-location {
        font-size: 14px !important;
    }
}
