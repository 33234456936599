/*.flex-pop-info{*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*}*/
.graph-top {
    padding: 14px 0px;
    padding-top: 15px;
    /*height: 355px;*/
    /*flex: 1 1 auto;*/
}
/*.graph-bot {*/
/*    padding: 10px 0px;*/
/*    !*padding-top: 15px;*!*/
/*    !*height: 355px;*!*/
/*    !*flex: 1 1 auto;*!*/
/*}*/