.main-g4-bar-container .child-bar{
  /*display: flex;*/
  /*flex-direction: column;*/
  margin-bottom: 20px;
}
.main-g4-left-container .child-g4-col {
  display: flex;
  /* flex-direction: column; */
  /* display: flex; */
  justify-content: space-between;
}
.main-g4-left-container .icon-male{
  font-size: 40px!important;
}
.main-g4-left-container .child-g4-col p{
  font-size: 15px;
  padding-left: 10px;
}
.main-g4-left-container .child-g4-col .child1,.child1-top {
  margin-right: 5px;

}
.main-g4-left-container .child-g4-col .ui.celled.grid {
  margin:5px 0px!important;

}
.main-g4-left-container .child-g4-col .child1-top .sign-amount{
  padding-left: 37px!important;
}
.main-g4-left-container .child-g4-col .child2-top .sign-amount{
  padding-left: 12px!important;
}
.main-g4-left-container .child-g4-col .child1,.child1-top .icon-male{
  top: -5px!important;
}
.main-g4-left-container .child-g4-col .child2,.child2-top .icon-male{
  top: -5px!important;
}
.main-g4-left-container .child-g4-col .child1 .icon-male{
  top: -5px!important;
}
.main-g4-left-container .child-g4-col .child2 .icon-male{
  top: -5px!important;
}
.main-g4-left-container .child-g4-col .child2,.child2-top {
  margin-left: 5px;
}
.main-g4-left-container .child-g4-row .pie-container{
  margin-top: 5px;
}
.main-g4-right-container{
  margin-top: 15px;
  /*display: flex;*/
}

@media only screen and (max-width: 768px) {
  .main-g4-left-container .icon-male{
    font-size: 40px!important;
  }
  .main-g4-left-container .child-g4-col p{
    font-size: 13px!important;
  }
  .main-g4-left-container .child-g4-col {
     flex-direction: column;
    /* display: flex; */
  }
  .main-g4-left-container .child-g4-col .child2,.child2-top {
    margin-left: 0px;
  }
  .main-g4-left-container .child-g4-col .child1,.child1-top {
    margin-right: 0px;

  }
}