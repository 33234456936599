.quetion-content {
    display: flex;
    flex: 1;
    width: 100%;
    border-radius: 10px;
    align-items: center;
    font-family: 'Kanit', sans-serif;
    overflow: auto;
}

.quetion-content .topic-menu {
    font-family: 'Kanit', sans-serif !important;
}

.topic-group {
    background-color:  var(--main2-color);
    color: #FFF;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 10px;
}

.question-sub-topic {
    display: flex;
    padding: 10px 40px;
    border-bottom: solid 1px #d7d7d7;
}

.question-sub-topic .no {
    margin-right: 10px;
    font-size: 16px;
}

.question-sub-topic .content {
    flex: 1
}

.question-sub-topic .content .block {
    display: flex;
}

.question-sub-topic .content .block .title {
    width: 60px;
    color: #8c8c8c
}
.question-sub-topic .content .block .question{
    color:  var(--main2-color);
    flex: 1;
}

.question-answer {
    display: flex;
    padding: 10px 40px;
}

.question-answer .no {
    margin-right: 10px;
    font-size: 16px;
}

.question-answer .content {
    flex: 1
}

.question-answer .content .block {
    display: flex;
}

.question-answer .content .block .title {
    width: 60px;
    color: #8c8c8c
}
.question-answer .content .block .question{
    color:  var(--main-color);
    flex: 1;
}

.question-container {
    min-width: 500px;
    /*border-radius: 10px !important;*/
}

.ui.menu {
    font-family: 'Kanit', sans-serif !important;
}
