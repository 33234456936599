.head-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--main2-color);
}
.horizon-bar-container {

  background-color: white;
  padding: 10px;
  border-radius: 10px;
  /*margin-top: 20px;*/
  transition-duration: 1s;
}
.horizon{
  position: relative;
  height: 67.2vh;
}
/*.horizon-bar-container:hover{*/
/*  transform: scale(1.05,1.05);*/
/*  transition-duration: 1s;*/
/*}*/
@media screen and (max-width: 1024px) {

  .horizon{
    /*position: relative;*/
    height: 40.4vh;
  }
}
