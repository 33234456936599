.status-house {
    display: flex;
    height: 100%;
    padding-top: 10px;
    /*align-items: center;*/
}

.status-house .line-status {
    border: solid 1px #D4D4D4;
    height: 200px;
    margin-left: 17px;
    margin-bottom: 15px;
}

.status-house .status-house-content {
    display: flex;
    position: absolute;
    flex-direction: column;
}

.status-house .status-house-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.status-house .status-house-container .icon-status {
    background-color: #D4D4D4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 7px 13px 10px;
    text-align: center;
    border-radius: 18px;
    margin-right: 15px;
    color: #FFFFFF;
}

.status-house .status-house-container .icon-status-now {
    background-color: #8DC75F;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 7px 13px 10px;
    text-align: center;
    border-radius: 18px;
    margin-right: 15px;
    color: #FFFFFF;
}

.status-house .status-house-container .icon-status-error {
    background-color: #ba1002;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 7px 13px 10px;
    text-align: center;
    border-radius: 18px;
    margin-right: 15px;
    color: #FFFFFF;
}

.status-house .status-house-container .icon-status-warning {
    background-color: #f5bf02;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 7px 13px 10px;
    text-align: center;
    border-radius: 18px;
    margin-right: 15px;
    color: #FFFFFF;
}

.status-house .status-house-container .text-status-now {
    color: #71AA43;
}

.status-house .status-house-container .text-status-error {
    color: #ba1002;
}

.status-house .status-house-container .text-status-warning {
    color: #f5bf02;
}
