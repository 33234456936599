.format-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #FFFFFF;
    position: relative;
    border-radius: 10px;
    font-family: 'Kanit', sans-serif;
    margin-top: 10px ;
    /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);*/
}

.format-container .content-title {
    position: absolute;
    display: flex;
    align-items: center;
    top: -12px;
    left: -23px;
}

.format-container .content-title .icon-title {
    background-color: var(--main-color);
    color: #ffffff;
    padding: 11px 8px;
    border-radius: 21px;
    position: absolute;
    font-size: 20px;
}

.format-container .content-title .text-title {
    color: #ffffff;
    background-color: var(--main-color);
    padding: 4px 25px;
    border-radius: 12px;
    margin-left: 20px;
    font-size: 15px;
}

.format-container .content-detail {
    /*margin: 25px 15px 10px 15px;*/
    margin: 25px 20px 15px 20px;
    height: 100%;

}
