.export-button{
  display: flex;

  border: none;
  position: relative;
  box-shadow: 5px 5px 4px #c7c7c7;
  outline: none;
  background-color: #FFFFFF !important;
  cursor: pointer;
  font-size: 14px !important;
  color: #616161;
  border-radius: 10px;
  align-items: center;
  padding: 10px 30px;
  margin: 5px;
}
.export-button .icon-export{
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  /*width: 40vw;*/
}
.export-button .progress-export{
  display: flex;


  justify-content: center;
  align-items: center;
  /*width: 40vw;*/
}
.export-button .progress-export .progress-bar{
  /*display: flex;*/
  margin: 0px!important;
  width:15vw;
}
.export-button .progress-export .label-loading{
  display: flex;
  justify-content: center;
  margin: 0px;
  /*margin-right: 5px;*/
  width: 35px;
}
.export-button .progress-export .cancel-export{
  margin: 0px;
  margin-left: 5px;
}