.approve-data-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
}

.approve-data-screen .header-container {
    left: -28px;
    display: flex;
    align-items: center;
    margin: 10px 0 30px 0;
    position: relative;
    flex-direction: row;
}

.approve-data-screen .header-container .button-back {
    font-size: 40px;
    outline: none;
    border: none;
    cursor: pointer;
}

.approve-data-screen .header-container  i{
    background-color: #ffffff;
    border-radius: 20px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--main-color);
}

.approve-data-screen .header-container .header-taxt-breadcumb {
    display: flex;
    flex-direction: column;
}

.approve-data-screen .header-container .header-taxt-breadcumb .header-text {
    font-size: 24px;
    color:  var(--main-color);
    margin-bottom: 10px;
    font-weight: bold;

}


/*css ช่องผู้สำรวจ*/
.approve-data-screen .header-container .survey-container {
    background-color: #FFF;
    padding: 10px;
    border-radius: 10px 0 0 10px;
    position: absolute;
    right: -60px;
    top: -10px;
}

.approve-data-screen .header-container .survey-container .survey-topic {
    font-size: 14px;
    font-weight: bold;
    color: #656565;
    width: 70px;
}

.approve-data-screen .header-container .survey-container .survey-desc {
    font-size: 15px;
    color: #656565;
}

/*คลาสนี้ใช้แสดงตัวอย่าง Layout ใช้จริงจะเหลือแค่ diplay,flex*/
.approve-data-screen .border-grid {
    display: flex;
    flex: 1;
    border-radius: 10px;
    height: 200px;
    align-items: center;
    justify-content: center;
}

.approve-data-screen .block-button {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 10px;

}

.approve-data-screen .block-button .approve-button {
    flex: 1;
    background-color: #1F82DA;
    margin-right: 5px;
    font-family: 'Kanit', sans-serif !important;
    color: #ffffff;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px;
}

.approve-data-screen .block-button .reject-button {
    flex: 1;
    background-color: #EB5757;
    margin-left: 5px;
    font-family: 'Kanit', sans-serif !important;
    color: #ffffff;
    border-radius: 10px;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 10px;
}

.approve-data-screen .block-button .icon-button {
    font-size: 12px;
    border: solid 2px #F0F5F9;
    border-radius: 15px;
    margin-right: 5px;
    padding: 2px 0px 0px 3px;

}

@media screen and (max-width: 520px) {

    /*css ช่องผู้สำรวจ*/
    .approve-data-screen .header-container .survey-container {
        top:50px
    }
    .approve-data-screen .space-top {
        padding-top: 50px !important;
    }
}

/*css survey person*/
@media screen and (max-width: 991px) {
    .survey-person-detail {
        margin-top: 45px;
    }

}

