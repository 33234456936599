.dropdown-profile {
    display: flex;
    flex: 1;

}

.dropdown-profile .dropdown-pattern-profile {
    width: 100%;
    border-radius: 20px !important;
    padding: 8px 15px;
    border-width: 1px;
    border-image: none;
    border-style: solid;
    font-family: 'Kanit', sans-serif !important;
    border-color: #979797;
    outline: none;
}

.dropdown-profile .ui.fluid.dropdown {
    border-radius: 20px !important;
    border: solid 1px #979797;
}

.address-item span.active {
    color: green
}

.address-item span {
    color: #8e8e8e
}


