.edit-profile-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
}

.edit-profile-screen .header-profile {
    font-size: 20px !important;
    font-family: 'Kanit', sans-serif !important;
}

.edit-profile-screen .profile-user {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 15px;
}

.edit-profile-screen .container-subheader {
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
}

.edit-profile-screen .container-subheader .subheader {
    padding: 10px;
    display: flex;
    align-items: center;
}

.edit-profile-screen .container-subheader .subheader .icon-header {
    font-size: 20px;
}

.edit-profile-screen .container-subheader .ui.divider {
    margin: 0px !important;
}

.edit-profile-screen .container-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}


.edit-profile-screen .container-content .divider-profile {
    border-left: solid 1px #EBEBEB;
    min-height: 250px;
    margin: 0px 10px;
}

.edit-profile-screen .container-content .container-content-input {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 10px 50px;
}

.edit-profile-screen .container-content .container-input {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin: 5px 0px;
}

.edit-profile-screen .container-content .container-input .label-detail {
    min-width: 120px;
}

.edit-profile-screen .container-content .container-input .label-address {
    min-width: 75px;
}

.edit-profile-screen .container-content .container-input Input {
    flex: 1;
    width: 100%;
    border-radius: 20px;
    padding: 6px 15px;
    border-width: 1px;
    border-image: none;
    border-style: solid;
    font-family: 'Kanit', sans-serif !important;
    border-color: #979797;
    outline: none;
}

.edit-profile-screen .container-content .container-input .ui.input {
    flex: 1;
}

.edit-profile-screen .container-content .container-input .input-status {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.edit-profile-screen .container-button-action {
    justify-content: center;
    display: flex;
    align-items: center;
}

.edit-profile-screen .container-button-action .button-action {
    border-radius: 20px;
    padding: 5px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
    background-image: linear-gradient(#4786BE, #1967AD);
}

.edit-profile-screen .container-button-action .button-action span {
    color: #FFFFFF;
    font-family: 'Kanit', sans-serif;
    padding: 5px 10px;
    min-width: 120px;
}


@media screen and (max-width: 1058px) {
    .edit-profile-screen .container-content .divider-profile {
        display: none;
    }

    .edit-profile-screen .container-content .img-profile {
        margin: 10px 55px;
    }

    .edit-profile-screen .container-content .container-content-input {
        margin: 10px 55px;
    }

}



