.member-list-screen {
    min-height: calc(100vh - 70px);
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
}

.member-list-screen .search-box .ui.selection.dropdown {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.member-list-screen .search-box .ui.selection.dropdown .ui.selection.dropdown>.dropdown.icon {
    top:1em;
}

.member-list-screen .header-text {
    color: #000;
    font-size: 24px;
    font-family: 'Kanit', sans-serif;
    padding-bottom: 15px;
}

.member-list-screen button {
    border-radius: 100px;
    align-items: center;
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
}

.member-list-screen .align-add-button {
    text-align: left !important;
}

.member-list-screen .add-button {
    border: none;
    background-image: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    color: #FFFFFF;
    align-items: center;
    box-shadow: 5px 5px 4px #c7c7c7;
    padding: 8px 10px;
    cursor: pointer;
    outline: none;
    font-size: 14px;
}

.member-list-screen .filter-button {
    border: none;
    box-shadow: 5px 5px 4px #c7c7c7;
    outline: none;
    /*padding: 8px 15px;*/
    background-color: #FFFFFF !important;
    cursor: pointer;
    font-size: 14px !important;
    color: #616161;
}

.member-list-screen .filter-button i {
    color: #979797;
}

.member-list-screen .add-button i {
    margin-right: 10px;
}

.member-list-screen .search-box {
    box-shadow: 5px 5px 4px #c7c7c7;
    display: flex;
    background-color: #FFFFFF;
    border-radius: 100px;
    flex: 1;
    margin-right: 10px;
}

.member-list-screen .ui.selection.active.dropdown:hover, .member-list-screen .ui.selection.active.dropdown, .member-list-screen .ui.selection.active.dropdown .menu {
    border-color: #FFF !important;
}

.member-list-screen .ui.selection.active.dropdown {
    border-top-left-radius: 25px !important;
}

.member-list-screen .search-box i.dropdown.icon {
    border: none !important;
}

.member-list-screen .search-box .dropdown-search {
    border: none !important;
    /*border-right: solid 2px #eeeded;*/
    background-color: transparent;
    border-right: solid 2px #c4c4c4 !important;
    border-radius: 100px 0px 0px 100px !important;
    outline: none !important;
    font-size: 10px;
}

.member-list-screen .search-box input {
    background-color: transparent;
    border: none;
    font-family: 'Kanit', sans-serif;
    outline: none;
    padding: 5px 10px;
    font-size: 14px;
    flex: 1;
}

.member-list-screen .search-box .search-content {
    display: flex;
    align-items: center;
    font-size: 10px;
    flex: 1;
}

.member-list-screen .search-box .search-content i.icon {
    height: auto;
    padding-right: 20px;
    color: #979797;
}

.filter-modal-screen .modal-filter {
    padding: 10px;
    margin: 0px;
}

.filter-modal-screen .modal-filter .header-filter {
    display: flex;
    padding-bottom: 15px;
    font-size: 20px !important;
    color: #00396D;
    font-weight: bold;
}

.filter-modal-screen .modal-filter .header-filter > div {
    display: flex;
    flex: 1;
    align-items: center;
}

.filter-modal-screen .modal-filter .header-filter .close-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #EB5757;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 10px;
    margin: 0px;
    font-size: 12px;
    border: none !important;
}

.filter-modal-screen .modal-filter .header-filter .close-modal-button {
    border: none;
    outline: none;
    padding: 0px !important;
    background-color: transparent;
    cursor: pointer;
}

.filter-modal-screen .modal-filter .filter-detail {
    margin-bottom: 10px;
}

.filter-modal-screen .modal-filter .filter-detail > div {
    margin-bottom: 5px;
    font-size: 14px !important;
}

.filter-modal-screen .modal-filter .button-filter-modal {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filter-modal-screen .modal-filter .button-filter-modal > a {
    text-decoration: underline;
    margin-right: 20px;
    font-size: 14px !important;
    cursor: pointer;
}

.filter-modal-screen .modal-filter .button-filter-modal .button-save-filter-modal {
    border: none;
    font-size: 14px !important;
    background-image: linear-gradient(#4685BD, #1967AD);
    color: white;
    padding: 8px 40px;
    outline: none;
    cursor: pointer;
    border-radius: 15px;
    font-family: 'Kanit', sans-serif !important;
}

.filter-modal-screen {
    padding: 15px 30px;
    font-family: 'Kanit', sans-serif !important;
    display: flex;
    flex-direction: column;
}

.member-list-screen .header-table {
    display: flex;
    flex-direction: row;
    border-radius: 10px 10px 0px 0px;
    padding: 10px;
    background-image: linear-gradient(0deg, var(--main-color) 31%, var(--main2-color) 100%);
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 10px;
    line-height: 15px;
}

.member-list-screen .header-table .space-block1 {
    width: 115px;
}

.member-list-screen .header-table .space-block1 .type-user-block {
    display: flex;
    /*flex: 1;*/
    margin-top: 5px;
}

.member-list-screen .header-table .type-symbol {
    height: 15px;
    width: 15px;
    border-radius: 100px;
    margin-right: 5px;
}

.member-list-screen .header-table .column-tools {
    width: 80px;
    display: flex;
    align-items: center;
}

.member-list-screen .header-table .row-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.member-list-screen .header-table .row-content .block2-content {
    display: flex;
    flex-wrap: wrap;
    line-height: 25px;
}

.member-list-screen .header-table .row-content .space-content {
    flex: 1 ;
    min-width: 100px;
    display: flex;
}

.member-list-screen .block-member-card {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.member-list-screen .count-page {
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
}

.member-list-screen .count-page .input-count {
    font-family: 'Kanit', sans-serif;
    font-size: 10px;
    outline: none;
    border: none;
    padding: 5px 5px;
    border-radius: 5px;
    width: 30px;
    justify-content: center;
    margin: 0px 5px;
}

.member-list-screen .count-page .input-count {
    border: 1px solid rgba(34, 36, 38, .15) !important;
    /*border-color: #EB5757 !important;*/
}

.member-list-screen .count-page .input-count .ui.selection.dropdown {
    border: none !important;
}

.member-list-screen .count-page .ui.selection.dropdown {
    min-width: 50px !important;
    padding: 14px 10px;
}

.member-list-screen .count-page .ui.selection.dropdown>.dropdown.icon {
    top: 1.5em;
}

.member-list-screen .count-page label {
    padding-right: 5px;

}

.member-list-screen .all-page {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.member-list-screen .count-page .ui.pagination.menu .active.item {
    outline: none;
}

.member-list-screen .count-page .ui.menu .item {
    font-size: 10px;
}

.member-list-screen .loading-block {
    background-color: white;
    margin-top: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

@media screen and (max-width: 1047px) {
    .member-list-screen .search-box .ui.selection.dropdown {
        min-width: 0px !important;
    }
}

/*ขนาดจอ 991px*/
@media screen and (max-width: 991px) {

    .member-list-screen .align-add-button {
        text-align: right !important;
    }

    .member-list-screen .filter-button {
        min-width: 80px;
    }

    .member-list-screen .search-box .ui.selection.dropdown {
        min-width: 14em !important;
    }

}


/*Ipad Screen*/
@media screen and (max-width: 850px) {

    .member-list-screen .align-add-button {
        text-align: right !important;
    }

    .member-list-screen .filter-button > label {
        display: none;
    }

    .member-list-screen .filter-button {
        padding: 8px 10px;
        font-size: 15px !important;
        min-width: 0px
    }

    .member-list-screen .filter-button > i {
        margin: 0px !important;
    }

    .member-list-screen .all-page {
        flex-direction: column;
    }

    .member-list-screen .header-table {
        font-size: 8px;
    }

    .member-list-screen .header-table .space-block1 {
        width: 90px;
    }

    .member-list-screen .header-table .column-content {
        flex-wrap: wrap;
    }

    .member-list-screen .header-table .column-content .space-content {
        min-width: 80px;
    }

    .member-list-screen .header-table .column-tools {
        display: flex;
        width: 30px;
    }

    .member-list-screen .header-table {
        padding: 8px !important;
        font-size: 10px;
    }
    .member-list-screen .search-box .ui.selection.dropdown {
        font-size: 10px;
    }

}


@media screen and (max-width: 600px) {

    .member-list-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .member-list-screen .add-button {
        font-size: 10px;
    }
    .member-list-screen .search-box input {
        font-size: 10px;
    }
    .member-list-screen .header-text {
        font-size: 20px;
    }
    .member-list-screen .header-table .row-content .block2-content
    {
        line-height: unset;
    }
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {

    .member-list-screen {
        padding: 15px;
    }

    .member-list-screen .header-text {
        font-size: 15px;
        padding-bottom: 5px !important;
    }

    .member-list-screen .add-button {
        padding: 5px 12px !important;
        font-size: 8px !important;
        margin-bottom: 5px;
    }

    .member-list-screen .search-box {
        font-size: 8px !important;
    }

    .member-list-screen .search-box .dropdown-search {
        font-size: 8px;
    }

    .member-list-screen .search-box input {
        font-size: 8px
    }

    .member-list-screen .filter-button {
        padding: 5px 8px;
        font-size: 8px !important;

    }

    .member-list-screen .count-page {
        font-size: 8px;
    }

    .member-list-screen .count-page .ui.menu .item {
        font-size: 8px;
    }

    .member-list-screen .header-table {
        padding: 5px !important;
        font-size: 8px !important;
    }

    /*.member-list-screen .header-table .column-tools {*/
    /*    display: none !important;*/
    /*    width: 0px;*/
    /*}*/

    .member-list-screen .header-table .row-content .space-content {
        min-width: 100px !important;;
    }

    .filter-modal-screen .modal-filter .modal-content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .filter-modal-screen .modal-filter {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .filter-modal-screen .ui.modal {
        width: 100% !important;
        height: 100%;
        margin: 0px !important;
        border-radius: 0px;
    }

    .filter-modal-screen .ui.dimmer {
        padding: 0px !important;
    }

}

/*Mini Mobile Screen*/
@media screen and (max-width: 350px) {
    .member-list-screen .header-text {
        font-size: 12px;
    }

    .member-list-screen .count-page {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .member-list-screen .count-page > label {
        margin-bottom: 5px;
    }

    .member-list-screen .header-table .row-content .space-content {
        width: 80px !important;
    }

}
