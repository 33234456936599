.approve-data {
    overflow-x: scroll;
}
.text-header {
    font-size: 14px;
    font-weight: bold;
}

.tr {
    line-height: 15px;
}

.tr:nth-child(odd) {
    background: #EBEBEB !important;
}

.cell-small {
    min-width: 70px;
}

.cell-medium {
    min-width: 135px;
}

.cell-large {
    min-width: 185px;
}

.pd-layout {
    /*padding: 40px 60px 40px 60px !important;*/
}

.radius {
    border-radius: 10px !important;
    border: 1px solid #C4C4C4 !important;
}

.left-cell-radius {
    border-radius: 10px 0px 0px 0px !important;
}

.right-cell-radius {
    border-radius: 0px 10px 0px 0px !important;
}

@media screen and (max-width: 850px) {

    .pd-layout {
        padding: 15px !important;
    }

    .text-header {
        font-size: 14px;
    }

    .cell-small {
        min-width: 62px;
    }

    .cell-medium {
        min-width: 120px;
    }

    .cell-large {
        min-width: 185px;
    }
}
