.card-house-list-list {
    /*background-color: brown;*/
    font-family: 'Kanit', sans-serif !important;

}

.card-house-list .container-border-card {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.card-house-list .members {
    display: flex;
    flex: 1;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.card-house-list .type-user-symbol {
    border-radius: 10px 0px 0px 10px;
    display: flex;
    width: 30px;

}

.card-house-list .type-user-symbol .background-house-no {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.card-house-list .type-user-symbol .background-house-no .house-no {
    min-width: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 15px;
    font-size: 10px;
    font-weight: bold;
}

.card-house-list .container-card {
    display: flex;
    padding: 5px;
    flex: 1;
}

.card-house-list .container-detail-house {
    display: flex;
    flex: 1;
    color: #4a4a4a;
    flex-wrap: wrap;
}

.card-house-list .container-detail-house .detail-table-block {
    display: flex;
    flex-direction: column;
    flex: 2;
    justify-content: center;
    min-width: 250px;
}
.subdetail-table-block .housedetail{
    padding: 10px 0;
    display: flex;
}
.card-house-list .container-detail-house .detail-table-block .subdetail-table-block {
    display: flex;
}

.card-house-list .header-detail-member {
    font-family: 'Kanit', sans-serif;
    color: #17436b;
    font-size: 20px;
    padding-top: 10px;
    /*padding: 10px 0*/
    /*padding: 10px 0*/
    /*font-weight: bold;*/
}

.card-house-list .container-text-detail {
    display: flex;
    flex-wrap: wrap;
}

.card-house-list .container-text-detail .space-block {
    flex: 1;
    min-width: 100px;
}

.card-house-list .text-detail-member {
    font-family: 'Kanit', sans-serif;
    color: #717171;
    padding-left: 8px;
}

.card-house-list .text-detail-member-success {
    font-family: 'Kanit', sans-serif;
    color: #6fa542;
    padding-left: 8px;
}

.card-house-list .text-detail-member-error {
    font-family: 'Kanit', sans-serif;
    color: #ba1002;
    padding-left: 8px;
}

.card-house-list .text-detail-member-warning {
    font-family: 'Kanit', sans-serif;
    color: #d9a909;
    padding-left: 8px;
}

.card-house-list .text-detail-member-address {
    display: flex;
    align-items: baseline;
    font-size: 12px !important;
    color: #9c9c9c;
}

.card-house-list .container-border-card .members .button-detail {
    padding-top: 10px;
    display: none;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    color: #00396D;
}

.card-house-list .container-border-card .members .ui.dropdown .menu {
    top: 20px !important;
}

.card-house-list .container-border-card .button-action {
    display: flex;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    margin-left: 10px !important;
}

.card-house-list .button-action i {
    color: #FFFFFF;
    margin: 0px;
}

.card-house-list .container-border-card .button-action-modal {
    display: flex;
    flex-direction: column;
    margin-left: 10px !important;
}

.card-house-list .container-border-card .button-action-modal i {
    color: #FFFFFF;
    margin: 0px;
}

.card-house-list .container-border-card .button-edit-member {
    flex: 1;
    background-color: rgb(242, 201, 76);
    margin-top: 5px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
}

.card-house-list .container-border-card .button-info-member {
    flex: 1;
    background-color: #2185d0 !important;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;

}


.card-house-list .container-border-card .layout-button-action {
    padding: 5px;
    display: flex;

}

.card-house-list .container-border-card .layout-button-action i {
    font-size: 12px !important;

}

.card-house-list .members .container-detail-user .container-text-detail div {
    display: flex;
    align-items: baseline;
    flex: 1;
}

.card-house-list .members .container-detail-user .container-text-detail i {
    padding-right: 5px;
    font-size: 12px;
}



/*Ipad Screen*/
@media screen and (max-width: 850px) {

    .card-house-list .layout-pic-profile {
        min-width: 62px;
    }

    .card-house-list .container-border-card .button-action {
        display: none;
    }

    .card-house-list .container-border-card .members .button-detail {
        display: flex;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        outline: none;
        color: #00396D;
        background-color: transparent;
    }

    /*.card-house-list .header-detail-member {*/
    /*    font-size: 10px !important;*/
    /*}*/

    /*.card-house-list .text-detail-member {*/
    /*    font-size: 10px !important;*/
    /*}*/
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {

    /*.card-house-list .header-detail-member {*/
    /*    font-size: 10px !important;*/
    /*}*/

    .card-house-list .layout-pic-profile {
        min-width: 60px;
    }

    /*.card-house-list .text-detail-member {*/
    /*    font-size: 8px !important;*/
    /*}*/


}

/*Mini Mobile Screen*/
@media screen and (max-width: 350px) {

}


