.survey-data-screen {

}

 /*css ช่องผู้สำรวจ*/
.survey-data-screen .survey-container {
}

.survey-data-screen .survey-container .survey-topic {
    font-size: 14px;
    font-weight: bold;
    color: #656565;
    width: 80px;
}

.survey-data-screen .survey-container .survey-desc {
    font-size: 15px;
    color: #656565;
}
