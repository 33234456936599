@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap");

* {
    font-family: "Kanit", sans-serif;
}
.main-layout-container {
    background-color: #EBEBEB;
}

@media only screen and (max-width: 767px) {
    .icon-male {
        font-size: 5rem !important;
        top: -35px;
    }
    .sign-amount p {
        font-size: 1rem;
    }
    .sign-sex p {
        font-size: 1rem;
    }

    .header-dash{
        margin-bottom: 0px!important;
    }
    .header-population-text{
        margin-bottom: 0px!important;
    }
}



