.display-center {
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.heading {
	background-image: linear-gradient(90deg, rgba(0,57,109,1) 0%, rgba(42,133,215,1) 100%);
	margin: 5px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	padding: 10px;
  color: #FFFFFF;
}

.button-edit {
  border-radius: 50px !important;
  margin-left: 10px !important;
  background-color: #E6A935 !important;
  color: #FFFFFF !important;
  /* background-image: linear-gradient(#FFFFFF, #E6A935) !important; */
}

.button-reset-password {
  border-radius: 50px !important;
  margin-right: 10px !important;
  background-color: #1967AD !important;
  color: #FFFFFF !important;
}

.set-icon-yellow {
  color: #E6A935 !important;
  background-color: #FFFFFF !important;
}

.set-icon-blue {
  color: #1967AD !important;
  background-color: #FFFFFF !important;
}

.border-bottom-solid {
  border-bottom: 1px solid #cccccc;
}

.set-label-padding-top {
  padding-top: 14px !important;
  padding-bottom: 14px;
  display: flex !important;
	align-items: center;
	justify-content: flex-end;
	/* padding: 0 !important; */
}

.set-padding-top {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.flex-end {
	display: flex !important;
	align-items: center;
	justify-content: flex-end;
	padding: 0 !important;
}

.pic-profile {
  margin: auto;
  width: 200px;
  border-radius: 100px;
}

.close-button {
  border-color: #FFFFFF !important;
  background-color: #FFFFFF !important;
}

.padding-left {
  padding-left: 100%;
}

.padding-bottom {
  padding-bottom: 0px;
}

.padding-top {
  padding-top: 0px !important;
}

.set-display-center {
  display: flex !important;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.set-margin-bottom-top {
  margin: 0px;
  margin-bottom: 10px;
  padding: 0px !important;
}

.set-display-margin-bottom{
  display: flex;
  margin-bottom: 20px;
}