.card-member {
    /*background-color: brown;*/
    font-family: 'Kanit', sans-serif !important;

}

.card-member .container-border-card {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.card-member .members {
    display: flex;
    flex: 1;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.card-member .type-user-symbol {
    border-radius: 10px 0px 0px 10px;
    display: flex;
    width: 30px;

}

.card-member .type-user-symbol .background-member-no {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.card-member .type-user-symbol .background-member-no .member-no {
    min-width: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 15px;
    font-size: 10px;
    font-weight: bold;
}

.card-member .container-card {
    display: flex;
    padding: 10px 15px;
    flex: 1;
}

.card-member .container-detail-user {
    display: flex;
    flex-direction: column;
    flex: 1;
    color: #4a4a4a;
}

.card-member .layout-pic-profile {
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-member .pic-profile {
    max-height: 40px;
    max-width: 40px;
    border-radius: 100px;
    border-color: #c4c4c4;
    border-width: 1px;
    margin-right: 10px;
}

.card-member .header-detail-member {
    font-family: 'Kanit', sans-serif;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 5px;
}

.card-member .container-text-detail {
    display: flex;
    flex-wrap: wrap;
    line-height: 25px;
}

.card-member .container-text-detail .space-block{
    flex: 1;
    min-width: 100px;
    font-size: 14px;
}

.card-member .text-detail-member {
    font-family: 'Kanit', sans-serif;
    font-size: 14px !important;
    color: #717171;
    padding-left: 5px;
}

.card-member .text-detail-member-address {
    display: flex;
    align-items: baseline;
    font-size: 14px !important;
    color: #717171;
}

.card-member .container-border-card .members .button-detail{
    padding-top: 10px;
    display: none;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    color: #00396D;
}

.card-member .container-border-card .members .ui.dropdown .menu {
    top:20px !important;
}

.card-member .container-border-card .button-action{
    display: flex;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    flex: 1;
}

.card-member .button-action i {
    color: #FFFFFF;
    margin: 0px;
}

.card-member .container-border-card .button-action-modal{
   display: flex;
    flex: 1;
}

.card-member .container-border-card .button-action-modal i {
    color: #FFFFFF;
    margin: 0px;
}

.card-member .container-border-card .button-edit-member{
    flex: 1;
    background-color: rgb(242, 201, 76);
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    margin-right: 5px;
}

.card-member .container-border-card .button-info-member{
    flex: 1;
    background-color: #2185d0 !important;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
color: #ffffff;
    margin-bottom: 5px;
}



.card-member .container-border-card .layout-button-action{
    padding: 5px;
    display: flex;
flex-direction: column;
}

.card-member .container-border-card .layout-button-action i{
    font-size: 12px!important;

}

.card-member .members .container-detail-user .container-text-detail  div {
    display: flex;
    align-items: baseline;
    flex: 1;
}

.card-member .members .container-detail-user .container-text-detail  i{
   padding-right: 5px;
    font-size: 15px;
}

/*Ipad Screen*/
@media screen and (max-width: 850px){

    .card-member .layout-pic-profile {
        min-width: 62px;
    }

    .card-member .container-border-card .button-action {
        display: none;
    }

    .card-member .container-border-card .button-action-modal{
        display: none;
    }
    .card-member .container-border-card .button-info-member{
        display: none;
    }
    .card-member .container-border-card .members  .button-detail{
        display: flex;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        outline: none;
        color: #00396D;
        background-color: transparent;
    }

    .card-member .container-border-card .layout-button-action{
        width: 0px;
    }

    .card-member .header-detail-member {
        font-size: 10px !important;
        padding-bottom: 0px;
    }

    .card-member .text-detail-member {
        font-size: 10px !important;
    }

    .card-member .container-text-detail {
        line-height: 15px;
    }
}

/*Mobile Screen*/
@media screen and (max-width: 450px) {

    .card-member .header-detail-member {
        font-size: 10px !important;
    }

    .card-member .layout-pic-profile {
        min-width: 60px;
    }

    .card-member .text-detail-member {
        font-size: 8px !important;
    }


}

/*Mini Mobile Screen*/
@media screen and (max-width: 350px) {

}


