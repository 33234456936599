.main-g5-left-container .child-g5-col{
  /*display: flex;*/
  margin-top: 14px;
}
.main-g5-top-container .child1{
  /*display: flex;*/
  margin-bottom: 15px;
  /*margin-right: 5px;*/
}

.main-g5-left-container .child-g5-col .sign-container .sign-sex p{
  font-size: 1.2rem;
}

.main-g5-left-container .child-g5-col .sign-container .sign-amount .text-unit{
  font-size: 0.8rem;
}
.main-g5-left-container .child-g5-col .sign-container .sign-amount .text-amount{
  font-size: 1rem;
}
.main-g5-left-container .child-g5-col .sign-container .icon-beer{
  /*width: 20px;*/
  /*height: 10px;*/
}
.main-g5-right-container .child1 .main-prog-container {
  margin-top: 14px;
  margin-bottom: 15px;
  padding-bottom: 72px;
}
.main-g5-right-container .child2 .main-prog-container {
  /*margin: 15px 0px;*/
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.main-g5-right-container .child3 .main-progOnce-container {

  margin-bottom: 14px;
}

@media only screen and (max-width: 767px) {
  .main-g5-left-container .icon-male{
    font-size: 50px!important;
    margin-top: 15px;
  }
}