.member-format {
    font-family: 'Kanit', sans-serif;
    border: solid 1px  var(--main2-color);
    border-radius: 10px;
    position: relative;
    padding: 25px 10px 10px 10px;
    /*margin: 0px 15px 30px 0px;*/
    margin-bottom: 30px;
    /*min-width: 250px;*/
}

.member-format .number-member {
    background-color:  var(--main2-color);
    padding: 10px 16px;
    border-radius: 25px;
    font-size: 15px;
    color: #FFF;
    position: absolute;
    top: -20px;
    left: 44%;
}

.member-format .detail-member {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    /*color: #848484;*/
    /*min-width: 250px;*/
}

.member-format .detail-member .header-member {
    font-size: 15px;
    font-weight: bold;
    color: #535353;
}

.member-format .detail-member .subheader-member {
    font-size: 12px;
    color: #535353;
}

.member-format .detail-member .subdetail-member {
    display: flex;
    flex: 1;
    width: 100%;
}

.member-format .detail-member .subdetail-member .topic-member {
    flex: 1;
    text-align: end;
    padding-right: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #808080;
}

.member-format .detail-member .subdetail-member .subtopic-member {
    flex: 1;
    font-size: 12px;
    color: #808080;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}


