.select-container {
  /* background-color: aqua; */
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;

  /*height: 100px;*/


}

.link-select {
  position: absolute;
  border-top: 1px solid gray;
  padding: 10px;
z-index: 0;
  width: 80%;
  transform: translateY(30%);
}
.menu-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  padding: 16px 17px;
  border-radius: 50px;
  border: 2px solid #ebebeb;
  font-weight: 900;
  transition: 0.5s;
}
.flex-menu-butt{

  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
  z-index: 0;
  /*padding: 10px 0px;*/
}
.flex-menu-butt1{

  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
  /*padding: 10px 0px;*/
}
.mt{
  margin-top: 12px;
}
.flex-menu-butt .label-but- ,.flex-menu-butt .label-but-false {

  font-size: 12px;
  margin: 0px;
}

.flex-menu-butt .label-but-active {
 color: var(--main-color);
  margin: 0px;
}
.menu-select p {
  margin: 0px;
}
.menu-select:hover {
  background-color: var(--main-color);
  border: 2px solid var(--main2-color) !important;
  transition: 0.5s;

  color: white;
  cursor: pointer;
}
.text-group {
  font-size: 20px;
}
.actives {
  background-color: var(--main2-color);
  /*border: 2px solid #00396d!important;*/
  /*z-index: 1;*/
  transition: 0.5s;
  color: white;
  cursor: pointer;
}
.active-2 {
  background-color: var(--main-color);

}
.active-3 {
  background-color: royalblue;
}
.active-4 {
  background-color: royalblue;
}
.active-5 {
  background-color: royalblue;
}
@media only screen and (max-width: 767px) {
  .menu-select {
    padding: 8px 9px;
  }
  .mt{
    margin-top: 12px;
  }
  .text-group {
    font-size: 10px;
  }

  .select-container{
    margin-top: 10px;
  }
}
