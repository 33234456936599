.main-g1-left-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-g1-right-container .donut-container{
  padding: 17.5px 10px;
  margin-top: 10px;
}
.main-g1-left-container .child-g1-row{
  flex: 1 1 auto;
  /*margin: 10px 0px;*/
  margin-top: 10px;
}
@media only screen and (max-width: 767px) {
  .main-g1-left-container .child-g1-row{
    margin-bottom: 10px!important;
  }
}