.main-mid-left-container {
  display: flex;
  flex-direction: column;
}
.child-sign {
  display: flex;
  justify-content: space-between;
}
.child-sign-col1 {
  margin: 0px 5px 0px 0px;
  flex: 1 1 auto;
}
.child-sign-col2 {
  margin: 0px 0px 0px 5px;
  flex: 1 1 auto;
}
.child-pie-col {
  /*margin: 10px 0px;*/
}
.mid-right-container{
  margin-top: 14px;
}
